@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.cta {
    @include mainSection;
    background-color: rgba(0, 0, 0, 0);
    padding: 3.0rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include tablet {
        padding: 4.0rem 0;
        height: 100vh;
    }

    @include desktop {
        padding: 4.0rem 0;
        height: 100vh;
    }

    &__title {
        @include pageTitle;
        color: $primary-light;
        font-size: 22px;
        text-align: center;
        padding-bottom: 1.0rem;

        @include tablet {
            max-width: 575px;
            font-size: 42px;
        }

        @include desktop {
            max-width: 750px;
            font-size: 48px;
        }
    }

    &__description {
        @include bodyCopy;

        @include tablet {
            font-size: 24px;
        }

        @include desktop {
            font-size: 28px;
        }

    }

    &__message-link {
        @include bodyCopy;

        @include tablet {
            font-size: 24px;
        }

        @include desktop {
            font-size: 28px;
        }

        &:hover {
            color: $secondary-dark;
        }
    }
}