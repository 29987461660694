@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.projects {
    padding-bottom: 1.0rem;

    @include tablet {
        padding-bottom: 2.0rem;
        display: flex;
    }

    @include desktop {
        padding-bottom: 3.0rem;
        display: flex;
    }

    &__link {
        text-decoration: none;
    }

    &__card {
        margin: 1.5rem 1.0rem;
        padding: 1.0rem;
        background: radial-gradient($secondary-accent, $tertiary-light);
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: translate 0.5s;
        height: 250px;
        overflow: hidden;

        @include tablet {
            padding: 2.0rem;
            height: 400px;
        }

        @include desktop {
            padding: 4.0rem;
            margin: 3.5rem 1.0rem;
            height: 600px;
        }

        &:hover {
            translate: 0 -20px;

            @include desktop {
                translate: 0 -40px;
            }
        }
    }

    &__card-subtitle {
        @include pageSubtitle;
        align-self: flex-end;
    }

    &__card-title {
        @include pageTitle;
        color: $secondary-dark;
        align-self: flex-end;
        padding-bottom: 1.0rem;

        @include tablet {
            padding-bottom: 2.0rem;
        }

        @include desktop {
            padding-bottom: 4.0rem;
        }

    }

    &__card-image {
        width: 80%;
        
    }
}