@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.about-page {
    @include mainSection;

    &__title {
        @include pageTitle;
        padding: 3.0rem 0 1.5rem;

        @include tablet {
            padding: 4.0rem 0 2.0rem;
        }

        @include desktop {
            padding: 5.0rem 0 2.5rem;
        }
    }

    &__description {
        @include bodyCopy;
        margin: 1.0rem 0;
        padding: 0 0.5rem;

        @include tablet {
            max-width: 70vw;
            margin: 1.5rem auto;
            padding: 0 1.5rem;
        }

        @include desktop {
            max-width: 70vw;
            margin: 1.5rem auto;
            padding: 0 3.0rem;
        }
    }

    &__profile-card {
        display: flex;
        align-items: center;
        padding: 3.0rem 0;
    }

    &__profile-img {
        width: 75px;
        border-radius: 50%;
        margin-right: 0.5rem;

        @include tablet {
            width: 100px;
            margin-right: 0.75rem;
        }

        @include desktop {
            width: 120px;
            margin-right: 1.0rem;
        }
    }

    &__signature {
        width: 120px;

        @include tablet {
            width: 140px;
        }

        @include desktop {
            width: 175px;
        }
    }

    &__link {
        text-decoration: none;
        color: $secondary-light;

        &:hover {
            color: $primary-accent;
        }
    }
}