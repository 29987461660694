@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.page-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &__nav {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 1.25rem;
        z-index: 999999;

        @include tablet {
            top: 1.5rem;
        }

        @include desktop {
            top: 2.0rem;
        }
    }

    &__nav-list {
        margin: 0;
        padding: 0.5rem;
        list-style: none;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        transition: all 0.5s linear;


        &--blur {
            border-radius: 25px;
            background-color: $tertiary-dark;
            backdrop-filter: blur(20px) saturate(1.7);
        }  
    }

    &__nav-item {
        @include bodyCopy;
        font-size: 12px;
        margin: 0;
        padding: 0 1.5rem;

        @include tablet {
            padding: 0 2.0rem;
        }

        @include desktop {
            padding: 0 3.0rem
        }

        &:hover {
            color: $primary-accent;
        }

        // FIXME: need to calculate padding when active is applied
        // &--active {
        //     background-color: $primary-light;
        //     padding: 0.65rem;
        //     border-radius: 25px;
        // }
        
    }

    &__nav-link {
        text-decoration: none;
    }
}