@use "./styles/partials/typography" as *;
@use "./styles/partials/mixins" as *;
@use "./styles/partials/variables" as *;

html, body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Mona Sans";
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  min-height: 100vh;
  padding: 0.75rem 0 0;
  background: linear-gradient($primary-background, $tertiary-dark, $secondary-background);

  @include tablet {
    padding: 1.5rem 0 0;
  }

  @include desktop {
    padding: 3.0rem 0 0;
  }
}