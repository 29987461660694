@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.main-hero {
    padding-top: 4.0rem;
    padding-bottom: 2.0rem;
    display: flex;
    flex-direction: column;

    @include tablet {
        padding-top: 6.0rem;
        padding-bottom: 3.0rem;
        width: 90%;
        flex-direction: row;
        align-items: flex-start;
    }

    @include desktop {
        padding-top: 8.0rem;
        padding-bottom: 3.0rem;
        width: 80%;

    }

    &__profile-img {
        width: 75px;
        border-radius: 50%;

        @include tablet {
            width: 115px;
        }

        @include desktop {
            width: 145px;
        }
    }

    &__profile-wrapper {
        display: flex;
        flex-direction: column;

        @include tablet {
            padding-left: 1.5rem;
        }
    }

    &__title {
        @include pageTitle;
        margin: 1.0rem 0;

        @include tablet {
            margin: 1.5rem 0;
        }

        @include desktop {
            margin: 1.5rem 0;
        }
    }

    &__intro {
        @include bodyCopy;
    }

    &__social-icons {
        width: 40%;
        display: flex;
        justify-content: space-between;

        @include tablet {
            width: 30%;
        }

        @include desktop {
            width: 30%;
        }
    }

    &__social-icon {
        width: 15px;
        margin: 1.0rem 0;

        @include tablet {
            width: 20px;
        }

        @include desktop {
            width: 25px;
        }

        &:hover {
            transform: scale(1.1);

            @include desktop {
                transform: scale(1.2);
            }
        }
    }
}