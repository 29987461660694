@use "./typography" as *;
@use "./variables" as *;

//BREAKPOINTS
@mixin tablet {
    @media (min-width: $tablet) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop) {
        @content;
    }
}

//CONTENT
@mixin mainSection {
    width: 100%;
    margin: 0;
    padding: 0 2.0rem;

    @include tablet {
        padding: 0 2.5rem;
    }

    @include desktop {
        padding: 0 3.5rem;
    }
}

@mixin pageTitle {
    margin: 0;
    font-family: "Mona Sans";
    font-size: 28px;
    font-weight: 700;
    color: $primary-accent;

    @include tablet {
        font-size: 32px;
    }

    @include desktop {
        font-size: 48px;
    }
}

@mixin pageSubtitle {
    margin: 0;
    font-family: "Mona Sans";
    font-size: 14px;
    font-weight: 400;
    color: $secondary-light;

    @include tablet {
        font-size: 16px;
    }

    @include desktop {
        font-size: 28px;
    }
}

@mixin bodyCopy {
    margin: 0;
    line-height: 1.5;
    font-family: "Mona Sans";
    font-size: 14px;
    font-weight: 200;
    color: $primary-light;

    @include tablet {
        font-size: 18px;
    }

    @include desktop {
        font-size: 22px;
    }
}