@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.contact-page {
    @include mainSection;

    &__title {
        @include pageTitle;
        padding: 3.0rem 0 1.5rem;

        @include tablet {
            padding: 4.0rem 0 2.0rem;
        }

        @include desktop {
            padding: 5.0rem 0 2.5rem;
        }
    }

    &__connect-wrapper {
        padding-bottom: 3.0rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;

        @include tablet {
            width: 60%;
        }

        @include desktop {
            width: 40%;
        }
    }

    &__connect-link {
        width: 27%;

    }

    &__connect-icon {
        width: 100%;
        padding: 1.0rem;


        &:hover {
            transform: scale(1.1);
        }
    }
}