@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.video-responsive {
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    margin-bottom: 2.0rem;

    @include desktop {
        margin-bottom: 3.0rem;
    }

    &__video {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border: none;
    }
}