@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.project-page {
    @include mainSection;
    padding-top: 3.0rem;
    padding-bottom: 3.0rem;

    @include tablet {
        padding-top: 4.0rem;
        padding-bottom: 3.0rem;
    }

    @include desktop {
        padding-top: 4.0rem;
        padding-bottom: 3.0rem;
    }

    &__nav-list {
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    &__nav-link {
        text-decoration: none;
        padding: 0.5rem;
    }

    &__nav-item {
        @include bodyCopy;

        &:hover {
            color: $secondary-accent;
        }
    }
}

.project {
    &__title {
        @include pageTitle;
        padding-top: 1.5rem;

        @include tablet {
            padding: 2.5rem 0 0.5rem;
        }

        @include desktop {
            padding: 3.5rem 0 0.75rem;
        }
    }

    &__subtitle {
        @include pageSubtitle;
    }

    &__img-container {
        padding: 1.5rem 0;
        display: flex;
        justify-content: space-between;

        @include desktop {
            padding: 2.5rem 0;
        }
    }

    &__img-mockup {
        width: 32%;
        
    }

    &__tech-stack {
        @include pageSubtitle;
        color: $secondary-light;
        font-weight: 300;
    }

    &__header {
        @include pageTitle;
        padding: 2.5rem 0 1.0rem;

        @include tablet {
            padding: 4.0rem 0 1.5rem;
        }

        @include desktop {
            padding: 5.0rem 0 2.0rem;
        }
    }

    &__problem-description {
        @include bodyCopy;
        padding-bottom: 0.5rem;
    }

    &__link {
        @include bodyCopy;
    }
}