// COLORS
// $primary-accent: #de5f54;
// $secondary-background: #c75ab4;
// $primary-background: #a364d0;
// $secondary-dark: #8296ea;
// $tertiary-dark: #aebdf1;
// $secondary-light: #dde3f9;
// $primary-light: #f6f8fe;
// $primary-dark: #2f2d2e;


$primary-light: #f6f8fe;
$secondary-light: #dde3f9;
$tertiary-light: #86BBD8;

$primary-dark: #001021;
$secondary-dark: #002c5c;
$tertiary-dark: #00346b;

$primary-background: #001933;
$secondary-background: #00264f;

$primary-accent: #de5f54;
$secondary-accent: #119DA4;

// BREAKPOINTS
$tablet: 768px;
$desktop: 1280px;